<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="flex md10 offset--md1">
        <div class="body-spacing">
          <div class="header-teacher">
            <div class="va-row">
              <div class="flex xs6 ddh-item">
                Dentro Educa – {{ teacherCredential.user.client_name }}
              </div>
              <div class="flex xs6 ddh-prof">
                <router-link class :to="{ name: 'main' }">
                  Encerrar sessão
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.2373 19.5721C13.625 20.1843 13.6415 21.1843 14.2373 21.7801C14.8478 22.3907 15.8348 22.3907 16.4453 21.7801L21.1279 17.0975C21.7385 16.4869 21.7385 15.5 21.1279 14.8895L16.4453 10.2068C15.8348 9.59627 14.8478 9.59627 14.2373 10.2068C13.6268 10.8173 13.627 11.8043 14.2373 12.4149L16.2465 14.4348H5.56522C4.70375 14.4348 4 15.1385 4 16C4 16.8615 4.70375 17.5652 5.56522 17.5652H16.2441L14.2373 19.5721Z"
                      fill="#FFFFFF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.1304 4H6.86957C5.27682 4 4 5.29156 4 6.86957V8.78261C4 9.64408 4.70375 10.3478 5.56522 10.3478C6.42668 10.3478 7.13044 9.64408 7.13044 8.78261V8.17391C7.13044 7.6006 7.6006 7.13044 8.17391 7.13044H23.8261C24.3994 7.13044 24.8696 7.6006 24.8696 8.17391V23.8261C24.8696 24.3994 24.3994 24.8696 23.8261 24.8696H8.17391C7.6006 24.8696 7.13044 24.3994 7.13044 23.8261V23.2174C7.13044 22.3559 6.42668 21.6522 5.56522 21.6522C4.70375 21.6522 4 22.3559 4 23.2174V25.1304C4 26.7093 5.29071 28 6.86957 28H25.1304C26.7093 28 28 26.7093 28 25.1304V6.86957C28 5.29071 26.7093 4 25.1304 4Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </router-link>

                <img
                  :src="teacherCredential.user.client_logo"
                  class="logo-client"
                  alt="logo"
                  width="120"
                />
              </div>
            </div>

            <div class="va-row">
              <div class="flex xs12 text-left school-name" v-if="gradeInfo">
                <h4>
                  <router-link :to="{ name: 'grades' }">
                    <span class="back-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <path
                          fill="#AFA2BA"
                          fill-rule="evenodd"
                          stroke="#AFA2BA"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.6"
                          d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
                        />
                      </svg>
                    </span>
                  </router-link>
                  <!-- {{ teacherCredential.name }} -->
                  {{ gradeInfo.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="teacher-grade va-row" v-if="teacherCredential">
        <div class="flex md10 offset--md1 xs12">
          <div class="body-spacing">
            <div class="va-row">
              <div class="flex md6 school-name-short">
                <span v-if="teacherCredential.currentGrade">{{
                  teacherCredential.currentGrade.school
                }}</span>
              </div>
              <div class="flex md6 xs12 text-right">
                <router-link
                  class="btn btn-primary"
                  target="_blank"
                  :to="{ name: 'LoginStudent' }"
                >
                  ÁREA DO ALUNO
                  <svg
                    width="24"
                    height="24"
                    class="fa svg"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.3333 24C25.3333 24.7333 24.7333 25.3333 24 25.3333H8C7.26667 25.3333 6.66667 24.7333 6.66667 24V8C6.66667 7.26667 7.26667 6.66667 8 6.66667H12.6667C13.4 6.66667 14 6.06667 14 5.33333C14 4.6 13.4 4 12.6667 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V19.3333C28 18.6 27.4 18 26.6667 18C25.9333 18 25.3333 18.6 25.3333 19.3333V24Z"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M17.7767 5.83333C17.7767 4.82386 18.6005 4 19.61 4H26.2767C27.2861 4 28.11 4.82386 28.11 5.83333V12.5C28.11 13.5095 27.2861 14.3333 26.2767 14.3333C25.2672 14.3333 24.4433 13.5095 24.4433 12.5V10.2538L13.1236 21.5736C12.4083 22.2888 11.2517 22.2888 10.5364 21.5736C9.82118 20.8583 9.82118 19.7017 10.5364 18.9864L21.8562 7.66667H19.61C18.6005 7.66667 17.7767 6.84281 17.7767 5.83333Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body-teacher" v-if="gradeInfo !== null">
      <div class="va-row">
        <div class="flex md10 offset--md1">
          <div class="body-spacing">
            <div class="va-row">
              <div
                class="flex"
                :class="
                  teacherCredential.settings.change_dedication
                    ? 'md8 xs12'
                    : 'md12 xs12'
                "
              >
                <div class="sets-list table-list-simple">
                  <div class="va-row header no-padding">
                    <div class="flex item">CRONOGRAMA</div>
                  </div>

                  <div class="empty-box-body">
                    <div class="schedules-list">
                      <div
                        v-for="schedule in gradeInfo.schedule"
                        :key="schedule.id"
                        class="schedule-item"
                      >
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.34459 16.4623L12.5001 20.6178L25.2018 7.92901C25.9821 7.14866 27.2442 7.14866 28.0246 7.92901C28.8049 8.70936 28.8049 9.97147 28.0246 10.7518L13.9179 24.8585C13.1376 25.6388 11.8755 25.6388 11.0951 24.8585L5.52179 19.2852C4.74144 18.5048 4.74144 17.2427 5.52179 16.4623C6.30214 15.682 7.56425 15.682 8.34459 16.4623Z"
                            fill="#DFDAE4"
                          />
                        </svg>

                        {{ schedule.date.replace("2020", "20") }}
                        <span>{{ schedule.name }}</span>
                      </div>
                    </div>

                    <hr />

                    <div class="va-row footer-panel">
                      <div
                        class="flex md6 date"
                        v-if="
                          teacherCredential.currentGrade
                            .teacher_approval_status == false &&
                          teacherCredential.currentGrade
                            .teacher_approval_delay_class !== 'red' &&
                          teacherCredential.currentGrade
                            .teacher_approval_delay_class !== 'yellow' &&
                          teacherCredential.currentGrade.status_id != 21
                        "
                      >
                        <div>
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_671_20790)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.69628 3.96429C6.69628 3.01751 7.49577 2.25 8.48199 2.25C9.46822 2.25 10.2677 3.01751 10.2677 3.96429V4.82144H14.7322V3.96429C14.7322 3.01751 15.5317 2.25 16.5179 2.25C17.5041 2.25 18.3036 3.01751 18.3036 3.96429V4.82144H19.7917C20.9423 4.82144 21.875 5.71687 21.875 6.82144V19.75C21.875 20.8546 20.9423 21.75 19.7917 21.75H5.20833C4.05774 21.75 3.125 20.8546 3.125 19.75V6.82144C3.125 5.71687 4.05774 4.82144 5.20833 4.82144H6.69628V3.96429ZM4.91076 10.8215V19.0213C4.91076 19.5736 5.37713 20.0213 5.95243 20.0213H19.0477C19.623 20.0213 20.0893 19.5736 20.0893 19.0213V10.8215H4.91076Z"
                                fill="#4965BC"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_671_20790">
                                <rect width="25" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                          <b
                            >Faltam
                            {{
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              )
                            }}
                            dias.</b
                          >
                        </div>
                      </div>

                      <div
                        class="flex md12 date date-yellow"
                        v-if="
                          teacherCredential.currentGrade.status_id == 20 &&
                          teacherCredential.currentGrade
                            .teacher_approval_delay_class == 'yellow'
                        "
                      >
                        <div>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_671_20821)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.7027 20.25H4.29728C2.72195 20.25 1.73993 18.5562 2.5276 17.2072L10.2303 4.01172C11.018 2.66276 12.982 2.66276 13.7697 4.01172L21.4724 17.2072C22.2601 18.5562 21.278 20.25 19.7027 20.25ZM13.7063 17.1506C13.7063 16.2244 12.9423 15.4735 12 15.4735C11.0577 15.4735 10.2938 16.2244 10.2938 17.1506C10.2938 18.0768 11.0577 18.8277 12 18.8277C12.9423 18.8277 13.7063 18.0768 13.7063 17.1506ZM13.5512 8.80079C13.5512 7.95876 12.8567 7.27617 12 7.27617C11.1434 7.27617 10.4489 7.95877 10.4489 8.80079V12.7132C10.4489 13.5552 11.1434 14.2378 12 14.2378C12.8567 14.2378 13.5512 13.5552 13.5512 12.7132V8.80079Z"
                                fill="#FEAF00"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_671_20821">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                          <span
                            v-if="
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              ) > 1
                            "
                          >
                            Faltam
                            {{
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              )
                            }}
                            dias.</span
                          >

                          <span
                            v-if="
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              ) == 1
                            "
                          >
                            Falta
                            {{
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              )
                            }}
                            dia.</span
                          >

                          <span
                            v-if="
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              ) == 0
                            "
                          >
                            Hoje termina o prazo.
                          </span>

                          {{
                            formatarDeDiferencaDeDias(
                              teacherCredential.currentGrade.teacher_approval
                            ) > 0
                              ? "Falta pouco para o prazo vencer."
                              : ""
                          }}
                        </div>
                      </div>

                      <div
                        class="flex md12 date date-red"
                        v-if="
                          teacherCredential.currentGrade.status_id == 20 &&
                          teacherCredential.currentGrade
                            .teacher_approval_delay_class == 'red'
                        "
                      >
                        <div>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.2402 3.21609L19.3385 3.10124C19.8219 3.51634 19.8943 4.24633 19.4762 4.7357C19.0601 5.23297 18.3312 5.29106 17.8451 4.88519L14.7534 2.30237C14.2699 1.88721 14.1982 1.15764 14.605 0.668583C15.0211 0.170692 15.7503 0.112333 16.2367 0.51839L19.3368 3.09976L19.2402 3.21609Z"
                              fill="#FF4641"
                            />
                            <path
                              d="M0.523087 4.73491C0.930132 5.23422 1.65972 5.29019 2.14481 4.88522L5.23522 2.3035C5.72974 1.88873 5.80263 1.1584 5.38504 0.668705C4.978 0.169384 4.2484 0.113411 3.76331 0.51839L0.663233 3.09975L0.661494 3.10124C0.178348 3.51612 0.105774 4.24554 0.523087 4.73491Z"
                              fill="#FF4641"
                            />
                            <path
                              d="M13.0841 14.9228C13.4934 15.1641 14.0305 15.0467 14.2769 14.6277C14.5293 14.2055 14.3992 13.657 13.9714 13.4114L13.9703 13.4107L10.6543 11.4391V6.95724C10.6543 6.47034 10.2646 6.07993 9.77856 6.07993H9.71816C9.23215 6.07993 8.84245 6.47034 8.84245 6.95724V11.7169C8.84245 12.1211 9.04999 12.5045 9.40987 12.7143L9.41084 12.7149L13.0829 14.9222L13.0841 14.9228Z"
                              fill="#FF4641"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10 2.04635C4.914 2.04635 0.789951 6.1779 0.789951 11.2732C0.789951 16.3684 4.914 20.5 10 20.5C15.086 20.5 19.2101 16.3684 19.2101 11.2732C19.2101 6.1779 15.086 2.04635 10 2.04635ZM10 18.1807C6.19806 18.1807 3.10505 15.082 3.10505 11.2732C3.10505 7.4643 6.19806 4.36566 10 4.36566C13.8019 4.36566 16.895 7.4643 16.895 11.2732C16.895 15.082 13.8019 18.1807 10 18.1807Z"
                              fill="#FF4641"
                            />
                          </svg>

                          <span
                            >Em atraso:
                            {{
                              formatarDeDiferencaDeDias(
                                teacherCredential.currentGrade.teacher_approval
                              )
                                .toString()
                                .replace("-", "")
                            }}
                            dias atrás.</span
                          >
                          Finalize sua turma e evite atraso no cronograma.
                          <!-- {{
											formatarDataParaExtensoComNumero(teacherCredential.currentGrade.teacher_approval)
										}}. Enviado
										{{
											formatarDataParaExtensoComNumero(
												teacherCredential.currentGrade.teacher_approval_realized_day
											)
										}}. -->
                        </div>
                      </div>

                      <div
                        class="flex md12 date date-green"
                        v-if="teacherCredential.currentGrade.status_id == 21"
                      >
                        <div>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.8832 12.3468L8.99983 15.4634L18.5261 5.94676C19.1114 5.3615 20.0579 5.3615 20.6432 5.94676C21.2285 6.53202 21.2285 7.4786 20.6432 8.06387L10.0632 18.6439C9.47794 19.2291 8.53136 19.2291 7.94609 18.6439L3.7661 14.4639C3.18083 13.8786 3.18083 12.932 3.7661 12.3468C4.35136 11.7615 5.29794 11.7615 5.8832 12.3468Z"
                              fill="#00CF72"
                            />
                          </svg>

                          <span>Enviado para impressão.</span>
                        </div>
                      </div>

                      <div
                        class="flex md12 date date-green"
                        v-if="
                          teacherCredential.currentGrade.status ==
                          'enviado para impressão'
                        "
                      >
                        <div
                          v-if="
                            teacherCredential.currentGrade.deadline &&
                            teacherCredential.currentGrade.delivery
                          "
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.8832 12.3468L8.99983 15.4634L18.5261 5.94676C19.1114 5.3615 20.0579 5.3615 20.6432 5.94676C21.2285 6.53202 21.2285 7.4786 20.6432 8.06387L10.0632 18.6439C9.47794 19.2291 8.53136 19.2291 7.94609 18.6439L3.7661 14.4639C3.18083 13.8786 3.18083 12.932 3.7661 12.3468C4.35136 11.7615 5.29794 11.7615 5.8832 12.3468Z"
                              fill="#00CF72"
                            />
                          </svg>

                          <span>Enviado para impressão.</span>
                          <!-- Prazo previsto
													{{
														formatarDataParaExtensoComNumero(
															teacherCredential.currentGrade.deadline
														)
													}}. Enviado
													{{
														formatarDataParaExtensoComNumero(
															teacherCredential.currentGrade.delivery
														)
													}}. -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-grade">
                  <div
                    class="sets-list table-list-simple student-list"
                    :class="gradeInfo.students.length === 0 ? 'list-empty' : ''"
                  >
                    <div class="va-row header no-padding">
                      <div class="flex item">MEUS ALUNOS</div>

                      <!-- <div class="flex item email">EMAIL</div> -->

                      <!-- <div class="flex item area">
											REGIÃO
										</div>
										<div class="flex item">
										
										</div> -->
                      <!-- <div class="flex item area">REGIÃO</div>
											<div class="flex item"></div> -->
                    </div>

                    <div class="empty-box-body">
                      <!-- <h4>
												{{ gradeInfo.name }}
												<span>•</span>
												Status: {{ gradeInfo.status }}
											</h4>
											<hr /> -->
                      <div class="info-grade va-row">
                        <div class="flex md7 xs12 no-padding info-text">
                          {{ gradeInfo.books_ordered }} Alunos na turma
                          <br />
                          {{ gradeInfo.books_completed }} Livros criados
                        </div>
                        <div
                          class="flex md5 xs12 text-right no-padding print-sent"
                        >
                          <a
                            :href="urlPrint"
                            target="_blank"
                            class="btn"
                            :disabled="!urlPrint"
                          >
                            IMPRIMIR LISTA DE ALUNOS
                          </a>
                        </div>
                      </div>

                      <hr />

                      <div class="flex xs12 text-center no-padding sent">
                        <div class="list-add-students">
                          <button
                            v-if="gradeInfo.status !== 'em execução'"
                            :disabled="true"
                            class="btn btn-add btn-success-print-button"
                          >
                            TUDO CERTO! OS LIVROS FORAM ENVIADOS PARA IMPRESSÃO
                          </button>
                        </div>

                        <div
                          class="empty-students"
                          v-if="
                            gradeInfo.students.length === 0 &&
                            gradeInfo.status === 'em execução'
                          "
                        >
                          <h3>Comece agora adicionando seus alunos!</h3>
                          <router-link
                            id="btn-crud-student"
                            :to="{
                              name: 'add-students',
                              params: { grade: gradeInfo },
                            }"
                            class="btn btn-add"
                          >
                            ADICIONAR ALUNOS
                          </router-link>
                        </div>

                        <div class="list-add-students">
                          <router-link
                            id="btn-crud-student"
                            v-if="
                              gradeInfo.students.length > 0 &&
                              gradeInfo.status === 'em execução'
                            "
                            :to="{
                              name: 'add-students',
                              params: { grade: gradeInfo },
                            }"
                            class="btn btn-add"
                          >
                            ADICIONAR OU EXCLUIR ALUNOS
                          </router-link>
                        </div>
                      </div>

                      <div class="panel-gray">
                        <div
                          class="va-row custom-teacher-table"
                          v-if="gradeInfo.students.length > 0"
                        >
                          <div
                            class="flex xs12 body-student"
                            v-for="student in gradeInfo.students"
                            :key="student.id"
                          >
                            <div
                              class="flex md12 xs12 header-table"
                              :id="'student-' + student.id"
                            >
                              <div class="va-row">
                                <div class="flex md6 xs12 align-name-student">
                                  {{ student.name }}
                                </div>
                                <div
                                  class="flex md6 xs12 student-code text-right"
                                >
                                  <span
                                    class="student-label label-position-codigo"
                                    >Código do aluno:
                                  </span>
                                  <span class="student-username">
                                    {{ student.username }}</span
                                  >
                                  <br />
                                  <div
                                    v-if="
                                      student.mooney_voucher &&
                                      student.mooney_voucher.length > 0
                                    "
                                  >
                                    <span class="student-label"
                                      >Acesso ao APP Mooney:
                                    </span>
                                    <span class="student-username">
                                      {{ student.mooney_voucher }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="empty-box-body"
                              :class="[
                                student.isCollapsedProducts
                                  ? 'collapsed-closed'
                                  : 'collapsed-open',
                              ]"
                              :style="
                                'height:' +
                                student.collapsedProductSizeHeight +
                                'px'
                              "
                            >
                              <!-- Com produto -->

                              <div
                                class="por-forinha"
                                :ref="'student-' + student.id"
                              >
                                <div
                                  class="va-row student-row"
                                  v-for="(
                                    product, productIndexRender
                                  ) in student.products"
                                  :key="productIndexRender"
                                >
                                  <div
                                    class="flex cover-colum"
                                    :class="
                                      teacherCredential.settings
                                        .change_dedication
                                        ? ''
                                        : ''
                                    "
                                  >
                                    <div class="bordered-cover">
                                      <div class="book-covers">
                                        <img
                                          height="182"
                                          v-lazy="
                                            require('@/assets/images/Product-A4-Book.png')
                                          "
                                          loading="lazy"
                                          alt="Dentro Educa"
                                          class
                                        />
                                      </div>
                                      <img
                                        v-if="product.isLoading === false"
                                        v-lazy="product.imageReady"
                                        loading="lazy"
                                        height="182"
                                        alt
                                      />

                                      <div
                                        class="cover-loading"
                                        v-else-if="product.isLoading == true"
                                      >
                                        <div class="loading-spinner-book">
                                          <svg
                                            version="1.1"
                                            id="L9"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 100 100"
                                            enable-background="new 0 0 0 0"
                                            xml:space="preserve"
                                          >
                                            <path
                                              fill="#fff"
                                              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                            >
                                              <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="rotate"
                                                dur="0.8s"
                                                from="0 50 50"
                                                to="360 50 50"
                                                repeatCount="indefinite"
                                              />
                                            </path>
                                          </svg>
                                        </div>

                                        <img
                                          height="182"
                                          v-lazy="
                                            require('@/assets/images/placeholder-a4.jpg')
                                          "
                                          loading="lazy"
                                          alt="Dentro Educa"
                                          class
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="flex student-name"
                                    :class="
                                      teacherCredential.settings
                                        .change_dedication
                                        ? ''
                                        : ''
                                    "
                                  >
                                    <div class="book-name">
                                      <span class="nome-da-marca">{{
                                        product.product.brand.name
                                      }}</span
                                      ><br />{{ product.product_name }}
                                    </div>
                                    <div v-if="gradeInfo.payment_control">
                                      <span>Pai / Responsável</span>
                                      {{
                                        student.parent == false
                                          ? `Não vinculado`
                                          : student.parent.name
                                      }}
                                    </div>
                                    <div
                                      v-if="
                                        gradeInfo.payment_control &&
                                        student.parent !== false
                                      "
                                    >
                                      <span>Pagamento</span>
                                      {{
                                        student.parent.paid == false
                                          ? `Aguardando`
                                          : `Pago`
                                      }}
                                    </div>
                                  </div>
                                  <div
                                    class="flex books-area align-self-center"
                                    :class="
                                      teacherCredential.settings
                                        .change_dedication
                                        ? ''
                                        : ''
                                    "
                                  >
                                    <div class="">
                                      <div class="flex no-padding text-right">
                                        <div class="">
                                          <div class="">
                                            <div
                                              class=""
                                              @click="
                                                doLoginStudentPreview(
                                                  product,
                                                  student.username,
                                                  teacherCredential.school_id
                                                )
                                              "
                                            >
                                              <button
                                                class="btn"
                                                :disabled="
                                                  product.isLoading == true
                                                "
                                              >
                                                <div
                                                  v-if="
                                                    product.isLoading === false
                                                  "
                                                >
                                                  VER LIVRO
                                                </div>
                                                <div
                                                  id="loading"
                                                  v-if="
                                                    product.isLoading === true
                                                  "
                                                ></div>
                                              </button>
                                            </div>

                                            <div class="text-center">
                                              <div
                                                v-if="
                                                  gradeInfo.status ===
                                                  'em execução'
                                                "
                                                class=""
                                                @click="
                                                  doLoginStudentProduct(
                                                    student.username,
                                                    teacherCredential.school_id,
                                                    product
                                                  )
                                                "
                                              >
                                                <button
                                                  class="btn inverter"
                                                  :disabled="
                                                    product.isLoading == true
                                                  "
                                                >
                                                  <div
                                                    v-if="
                                                      product.isLoading ===
                                                      false
                                                    "
                                                  >
                                                    EDITAR AVATAR
                                                  </div>
                                                  <div
                                                    id="loading"
                                                    v-if="
                                                      product.isLoading === true
                                                    "
                                                  ></div>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- Sem produto -->
                              <div
                                class="va-row student-row"
                                v-if="student.products.length === 0"
                              >
                                <div class="flex cover-colum">
                                  <div class="bordered-cover">
                                    <img
                                      height="182"
                                      :src="
                                        require('@/assets/images/asset-placeholder.png')
                                      "
                                      alt="Dentro Educa"
                                      class
                                    />
                                  </div>
                                </div>
                                <div class="flex student-name"></div>

                                <div class="flex align-self-center">
                                  <div class="va-row">
                                    <div class="btn-micro-edit text-center">
                                      <div class="empty-item">
                                        <div
                                          class="empty-book login-student-2"
                                          @click="
                                            doLoginStudent(
                                              student.username,
                                              teacherCredential.school_id,
                                              false,
                                              true
                                            )
                                          "
                                        >
                                          <div>
                                            <button class="btn">
                                              CRIAR LIVRO
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- Botão caso exista mais de um produto -->
                              <button
                                v-if="student.products.length > 1"
                                class="btn-collapse-control"
                                @click="openCollapseProducts(student)"
                              >
                                <span
                                  v-if="student.collapsedProductSizeHeight == 0"
                                  >Mostrar {{ student.products.length }} livros
                                  <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.0068 16.0107C11.8502 16.0107 11.7013 15.9835 11.5601 15.9291C11.4188 15.8746 11.2895 15.786 11.1721 15.6632L6.3198 10.5904C6.09819 10.3588 5.99175 10.0724 6.0005 9.73147C6.00924 9.39051 6.12442 9.10418 6.34603 8.8725C6.56764 8.64082 6.84589 8.52497 7.18078 8.52497C7.51566 8.52497 7.79391 8.64082 8.01552 8.8725L12.0068 13.0452L16.0243 8.84508C16.2459 8.61339 16.5198 8.50212 16.846 8.51126C17.1721 8.5204 17.446 8.64082 17.6676 8.8725C17.8892 9.10418 18 9.39508 18 9.74518C18 10.0953 17.8892 10.3862 17.6676 10.6179L12.8416 15.6632C12.7241 15.786 12.5948 15.8746 12.4536 15.9291C12.3123 15.9835 12.1634 16.0107 12.0068 16.0107Z"
                                      fill="#4965BC"
                                    />
                                  </svg>
                                </span>
                                <span v-else
                                  >Ocultar livros
                                  <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.33216 14.703C6.11072 14.4716 6 14.1811 6 13.8315C6 13.4819 6.11072 13.1913 6.33216 12.96L11.1544 7.92129C11.2717 7.79864 11.4009 7.71014 11.542 7.65577C11.6831 7.6014 11.8319 7.57422 11.9884 7.57422C12.1449 7.57422 12.2937 7.6014 12.4348 7.65577C12.5759 7.71014 12.7052 7.79864 12.8225 7.92129L17.6709 12.9874C17.8924 13.2187 18.0021 13.5012 18 13.8348C17.9979 14.1683 17.8795 14.4578 17.6447 14.703C17.4233 14.9344 17.1453 15.0501 16.8106 15.0501C16.476 15.0501 16.198 14.9344 15.9766 14.703L11.9884 10.5359L7.97411 14.7304C7.75268 14.9618 7.48234 15.0764 7.16309 15.0742C6.84387 15.072 6.56689 14.9483 6.33216 14.703Z"
                                      fill="#4965BC"
                                    /></svg
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="schedules-list">
											<div
												v-for="schedule in gradeInfo.schedule"
												:key="schedule.id"
												class="schedule-item"
											>
												<svg
													width="33"
													height="32"
													viewBox="0 0 33 32"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8.34459 16.4623L12.5001 20.6178L25.2018 7.92901C25.9821 7.14866 27.2442 7.14866 28.0246 7.92901C28.8049 8.70936 28.8049 9.97147 28.0246 10.7518L13.9179 24.8585C13.1376 25.6388 11.8755 25.6388 11.0951 24.8585L5.52179 19.2852C4.74144 18.5048 4.74144 17.2427 5.52179 16.4623C6.30214 15.682 7.56425 15.682 8.34459 16.4623Z"
														fill="#DFDAE4"
													/>
												</svg>

												{{ schedule.date.replace("2020", "20") }}
												<span>{{ schedule.name }}</span>
											</div>
										</div> -->
                    </div>
                  </div>

                  <!-- <hr class="item-line" /> -->

                  <div class="panel-gray">
                    <div
                      class=""
                      v-if="
                        teacherCredential.settings.teacher_book ||
                        teacherCredential.settings.teacher_book_cortesy
                      "
                    >
                      <div class="custom-teacher-table teacher-table-itens">
                        <div class="sets-list table-list-simple">
                          <div class="va-row header no-padding">
                            <div class="flex item md12">LIVRO DO PROFESSOR</div>
                          </div>

                          <div class="empty-box-body">
                            <div class="">
                              <!-- {{ gradeInfo.teacher }} -->
                              <div class="va-row" v-if="gradeInfo.teacher">
                                <div class="flex xs12 body-student">
                                  <div
                                    class="flex md12 xs12 header-table"
                                    :id="'student-' + gradeInfo.teacher.id"
                                  >
                                    <div class="va-row">
                                      <div class="flex md6 xs12">
                                        {{ gradeInfo.teacher.name }}
                                      </div>
                                      <div
                                        class="flex md6 xs12 student-code text-right"
                                      ></div>
                                    </div>
                                  </div>

                                  <div
                                    class="empty-box-body"
                                    :class="[
                                      gradeInfo.teacher.isCollapsedProducts
                                        ? 'collapsed-closed'
                                        : 'collapsed-open',
                                    ]"
                                    :style="
                                      'height:' +
                                      gradeInfo.teacher
                                        .collapsedProductSizeHeight +
                                      'px'
                                    "
                                  >
                                    <div
                                      class="por-forinha"
                                      :ref="'student-' + gradeInfo.teacher.id"
                                    >
                                      <div
                                        class="va-row student-row"
                                        v-for="(
                                          productTeacher, productIndexTeacher
                                        ) in gradeInfo.teacher.products"
                                        :key="productIndexTeacher"
                                      >
                                        <div
                                          class="flex cover-colum text-center"
                                        >
                                          <div class="bordered-cover">
                                            <div class="book-covers">
                                              <img
                                                height="182"
                                                v-lazy="
                                                  require('@/assets/images/Product-A4-Book.png')
                                                "
                                                loading="lazy"
                                                alt="Dentro Educa"
                                                class
                                              />
                                            </div>
                                            <img
                                              v-lazy="
                                                'https://builder.dentrodahistoria.com.br/api/builder/getpreview/' +
                                                productTeacher.builder_uuid +
                                                '?page=1&w=300&noCache=true' +
                                                (productTeacher.randomClear
                                                  ? productTeacher.randomClear
                                                  : '')
                                              "
                                              loading="lazy"
                                              alt
                                              height="182"
                                              v-if="productTeacher.builder_uuid"
                                            />

                                            <div
                                              class="cover-loading"
                                              v-else-if="
                                                !productTeacher.builder_uuid
                                              "
                                            >
                                              <div class="loading-spinner-book">
                                                <svg
                                                  version="1.1"
                                                  id="L9"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                                  x="0px"
                                                  y="0px"
                                                  viewBox="0 0 100 100"
                                                  enable-background="new 0 0 0 0"
                                                  xml:space="preserve"
                                                >
                                                  <path
                                                    fill="#fff"
                                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                  >
                                                    <animateTransform
                                                      attributeName="transform"
                                                      attributeType="XML"
                                                      type="rotate"
                                                      dur="1s"
                                                      from="0 50 50"
                                                      to="360 50 50"
                                                      repeatCount="indefinite"
                                                    />
                                                  </path>
                                                </svg>
                                              </div>

                                              <img
                                                height="182"
                                                v-lazy="
                                                  require('@/assets/images/placeholder-a4.jpg')
                                                "
                                                loading="lazy"
                                                alt="Dentro Educa"
                                                class
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div class="flex student-name">
                                          <div class="book-name">
                                            <span class="nome-da-marca">{{
                                              productTeacher.product.brand.name
                                            }}</span
                                            ><br />
                                            {{ productTeacher.product_name }}
                                          </div>
                                        </div>
                                        <div
                                          class="flex books-area align-self-center"
                                        >
                                          <div class="">
                                            <div class="text-right">
                                              <div class="">
                                                <div class="">
                                                  <div class="text-center">
                                                    <div
                                                      class=""
                                                      @click="
                                                        doLoginStudentPreview(
                                                          productTeacher,
                                                          gradeInfo.teacher
                                                            .username,
                                                          teacherCredential.school_id
                                                        )
                                                      "
                                                    >
                                                      <button
                                                        class="btn"
                                                        :disabled="
                                                          !productTeacher.builder_uuid
                                                        "
                                                      >
                                                        VER LIVRO
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div class="text-center">
                                                    <div
                                                      v-if="
                                                        gradeInfo &&
                                                        gradeInfo.status ===
                                                          'em execução'
                                                      "
                                                      class=""
                                                      @click="
                                                        doLoginStudentProduct(
                                                          gradeInfo.teacher
                                                            .username,
                                                          teacherCredential.school_id,
                                                          productTeacher
                                                        )
                                                      "
                                                    >
                                                      <button
                                                        class="btn inverter"
                                                      >
                                                        EDITAR AVATAR
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <hr class="short-line" />
                                      </div>
                                    </div>

                                    <div
                                      class="student-row va-row"
                                      v-if="
                                        gradeInfo.teacher.products.length == 0
                                      "
                                    >
                                      <div class="flex">
                                        <div class="bordered-cover">
                                          <img
                                            height="182"
                                            :src="
                                              require('@/assets/images/asset-placeholder.png')
                                            "
                                            alt="Dentro Educa"
                                            class
                                          />
                                        </div>
                                      </div>
                                      <div class="flex student-name"></div>
                                      <div
                                        class="flex books-area align-self-center text-center"
                                      >
                                        <div class="">
                                          <div class="text-center">
                                            <div class="empty-item">
                                              <div
                                                class="empty-book login-student-1"
                                                @click="
                                                  doLoginStudent(
                                                    gradeInfo.teacher.username,
                                                    teacherCredential.school_id,
                                                    false,
                                                    true
                                                  )
                                                "
                                              >
                                                <div>
                                                  <button class="btn">
                                                    CRIAR LIVRO
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <button
                                      v-if="
                                        gradeInfo.teacher.products.length > 1
                                      "
                                      class="btn-collapse-control"
                                      @click="
                                        openCollapseProducts(gradeInfo.teacher)
                                      "
                                    >
                                      <span
                                        v-if="
                                          gradeInfo.teacher
                                            .collapsedProductSizeHeight == 0
                                        "
                                        >Mostrar
                                        {{ gradeInfo.teacher.products.length }}
                                        livros
                                        <svg
                                          width="24"
                                          height="25"
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12.0068 16.0107C11.8502 16.0107 11.7013 15.9835 11.5601 15.9291C11.4188 15.8746 11.2895 15.786 11.1721 15.6632L6.3198 10.5904C6.09819 10.3588 5.99175 10.0724 6.0005 9.73147C6.00924 9.39051 6.12442 9.10418 6.34603 8.8725C6.56764 8.64082 6.84589 8.52497 7.18078 8.52497C7.51566 8.52497 7.79391 8.64082 8.01552 8.8725L12.0068 13.0452L16.0243 8.84508C16.2459 8.61339 16.5198 8.50212 16.846 8.51126C17.1721 8.5204 17.446 8.64082 17.6676 8.8725C17.8892 9.10418 18 9.39508 18 9.74518C18 10.0953 17.8892 10.3862 17.6676 10.6179L12.8416 15.6632C12.7241 15.786 12.5948 15.8746 12.4536 15.9291C12.3123 15.9835 12.1634 16.0107 12.0068 16.0107Z"
                                            fill="#4965BC"
                                          /></svg
                                      ></span>
                                      <span v-else
                                        >Ocultar livros
                                        <svg
                                          width="24"
                                          height="25"
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.33216 14.703C6.11072 14.4716 6 14.1811 6 13.8315C6 13.4819 6.11072 13.1913 6.33216 12.96L11.1544 7.92129C11.2717 7.79864 11.4009 7.71014 11.542 7.65577C11.6831 7.6014 11.8319 7.57422 11.9884 7.57422C12.1449 7.57422 12.2937 7.6014 12.4348 7.65577C12.5759 7.71014 12.7052 7.79864 12.8225 7.92129L17.6709 12.9874C17.8924 13.2187 18.0021 13.5012 18 13.8348C17.9979 14.1683 17.8795 14.4578 17.6447 14.703C17.4233 14.9344 17.1453 15.0501 16.8106 15.0501C16.476 15.0501 16.198 14.9344 15.9766 14.703L11.9884 10.5359L7.97411 14.7304C7.75268 14.9618 7.48234 15.0764 7.16309 15.0742C6.84387 15.072 6.56689 14.9483 6.33216 14.703Z"
                                            fill="#4965BC"
                                          /></svg
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="custom-teacher-table">
                    <div class="sets-list table-list-simple">
                      <div class="va-row header no-padding">
                        <div
                          class="flex item md12"
                          v-if="gradeInfo.release_print_enabled"
                        >
                          IMPRIMIR LIVROS
                        </div>
                        <div
                          class="flex item md12"
                          v-if="gradeInfo.status === 'enviado para impressão'"
                        >
                          LIVROS JÁ ENVIADOS PARA IMPRESSÃO
                        </div>

                        <div class="flex item md12" v-else>
                          STATUS DE IMPRESSÃO
                        </div>
                      </div>

                      <div class="">
                        <div
                          class="flex xs12 body-student body-status print-area"
                        >
                          <div class="va-row">
                            <div
                              class="phrase-secure va-row"
                              v-if="gradeInfo.status === 'em execução'"
                            >
                              <div class="flex md6 xs12 status-text">
                                <div>
                                  <b>Atenção:</b>
                                  <br />Para imprimir, é preciso que todos os
                                  alunos estejam com os livros criados.
                                </div>
                              </div>
                              <div class="flex md6 xs12 text-right">
                                <button
                                  class="btn btn-send"
                                  v-if="gradeInfo.status === 'em execução'"
                                  :disabled="!gradeInfo.release_print_enabled"
                                  @click="showModal()"
                                >
                                  ENVIAR PARA IMPRESSÃO
                                </button>
                              </div>
                            </div>

                            <div
                              class="phrase-secure va-row"
                              v-if="
                                gradeInfo.status === 'enviado para impressão' ||
                                gradeInfo.status === 'enviando para impressão'
                              "
                            >
                              <div class="flex md12 xs12 status-text">
                                <div>
                                  <b>Tudo certo!</b>
                                  <br />Os livros foram enviados para impressão
                                  com sucesso. Agora é só relaxar e aguardar os
                                  livros de seus alunos chegarem.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <vuestic-modal
                    :show.sync="show"
                    v-bind:large="true"
                    ref="largeModal"
                    :okText="'modal.confirm' | translate"
                    :cancelText="'modal.cancel' | translate"
                    :noButtons="true"
                  >
                    <div class="flex xs12 text-center">
                      <h1>
                        Tem certeza de que os livros
                        <br />estão todos certinhos?
                      </h1>
                      <p>
                        A partir de agora não será possível fazer
                        <br />alterações e logo eles serão impressos!
                      </p>

                      <img
                        :src="require('@/assets/images/impressora.png')"
                        alt
                        class="congrats"
                      />
                      <br />
                      <br />

                      <div class="flex xs12 no-padding-right text-center">
                        <button
                          type="button"
                          class="btn btn-primary cancel"
                          v-on:click="closeModal()"
                        >
                          NÃO, QUERO REVISAR OS LIVROS.
                        </button>
                        &nbsp;
                        <button @click="finish()" class="btn btn-primary">
                          SIM, QUERO FINALIZAR!
                        </button>
                      </div>
                    </div>
                  </vuestic-modal>

                  <vuestic-modal
                    :show.sync="show"
                    v-bind:large="true"
                    ref="exampleDedication"
                    :okText="'modal.confirm' | translate"
                    :cancelText="'modal.cancel' | translate"
                    :noButtons="true"
                  >
                    <div class="flex xs12 text-left">
                      <h3>Veja um exemplo de dedicatória:</h3>

                      <p>
                        “Aproveite a chance de se aventurar dentro de uma
                        história e vivenciar a experiência da leitura e do
                        aprendizado!”
                      </p>
                    </div>

                    <div class="flex xs12 text-center">
                      <button
                        type="button"
                        class="btn btn-primary cancel"
                        v-on:click="closeExample()"
                      >
                        FECHAR
                      </button>
                    </div>
                  </vuestic-modal>
                </div>
              </div>

              <div
                class="flex md4 xs12 dedication"
                v-if="teacherCredential.settings.change_dedication"
              >
                <div class="panel right">
                  <h4>Dedicatória</h4>
                  <p>
                    Escreva uma dedicatória para ser impressa no livro dos
                    alunos.
                  </p>

                  <div class="va-row">
                    <div class="flex xs12 no-padding text-center">
                      <div
                        class="dedication-area"
                        :class="[
                          dedicationIsActive ? 'active' : 'inactive',
                          dedicationIsActive && dedicationCounter == 0
                            ? 'red'
                            : 'green',
                        ]"
                      >
                        <span>Mensagem do professor:</span>

                        <textarea
                          id="dedication-text"
                          maxlength="350"
                          required="true"
                          :disabled="!dedicationIsActive"
                          @keyup="onEnter"
                          :value="dedication"
                          placeholder="Estou digitando uma dedicatóri…."
                        ></textarea>

                        <div
                          class="counter"
                          v-if="
                            dedicationIsActive &&
                            dedication &&
                            dedication.length
                          "
                          :class="[
                            dedicationIsActive ? 'active' : 'inactive',
                            dedicationCounter == 0 ? 'red' : 'green',
                          ]"
                        >
                          Caracteres: {{ dedicationCounter }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex xs12 no-padding-right text-right"
                      v-if="!dedicationIsActive"
                    >
                      <button
                        id="btn-edit-dedication"
                        class="btn"
                        v-if="gradeInfo.status === 'em execução'"
                        @click.prevent="editDedication()"
                      >
                        EDITAR
                      </button>
                    </div>

                    <div
                      class="flex xs12 no-padding-right text-right"
                      v-if="dedicationIsActive"
                    >
                      <button
                        type="button"
                        class="btn btn-primary cancel"
                        @click.stop.prevent="cancelEditDedication()"
                      >
                        CANCELAR
                      </button>
                      &nbsp;
                      <button
                        id="btn-save-dedication"
                        @click="sendDedication"
                        class="btn"
                      >
                        SALVAR
                      </button>
                    </div>
                  </div>

                  <hr
                    v-if="
                      gradeInfo.status === 'em execução' &&
                      teacherCredential.settings.change_dedication
                    "
                  />

                  <div class="flex xs12 text-center button-area">
                    <button
                      v-if="
                        gradeInfo.status === 'em execução' &&
                        teacherCredential.settings.change_dedication
                      "
                      class="btn btn-white"
                      @click="showModalExample()"
                    >
                      VER EXEMPLO DE DEDICATÓRIA
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { months, maxAgeAllowed, monthsHelper } from "../student/utils.js";
import Vue from "vue";
window.Vue = Vue;

const axios = require("axios");

export default {
  name: "Grade",
  directives: { mask },
  computed: {
    ...mapGetters({
      teacherCredential: "teacherCredential",
      studentCredential: "studentCredential",
    }),
  },

  props: ["grade"],

  data() {
    return {
      list: null,

      schoolInfo: null,
      gradeInfo: null,

      show: false,

      urlPrint: null,

      oldDedication: "",
      dedication: "",
      dedicationIsActive: false,
      dedicationCounter: 0,

      extraFiles: [],

      urlSendToPrint: null,

      months: months,

      refreshIntervalId: null,
      intervalList: [],

      controller: null,
      signal: null,
    };
  },

  beforeDestroy() {
    const self = this;

    //clearInterval(self.refreshIntervalId);
    self.clearAllIntervals();
    self.controller.abort();
    axios.CancelToken.source();
    console.log("Clean intervals");
  },

  mounted() {
    const self = this;

    if (self.grade) {
      self.$store.commit("SAVE_TEACHER_CURRENT_GRADE", self.grade);
    }

    this.$root.$on("closeModal", () => {
      self.closeModal();
    });

    self.controller = new AbortController();
    self.signal = self.controller.signal;

    let config = {
      headers: {
        Authorization: "Bearer " + this.teacherCredential.token,
      },
      signal: self.signal,
    };

    console.log("Limpei a gradeInfo");
    self.gradeInfo = null;

    axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "/grades/" +
          this.teacherCredential.currentGrade.id,
        config
      )
      .then((response) => {
        self.gradeInfo = response.data.data.grade;

        if (response.data.data.grade && response.data.data.grade.dedication) {
          self.dedication = response.data.data.grade.dedication;
          self.dedicationCounter = 350 - self.dedication.length;
          self.oldDedication = response.data.data.grade.dedication;
        }

        console.log("Percorre alunos.");

        // Passo 0

        if (self.gradeInfo.teacher.products.length > 1) {
          self.gradeInfo.teacher.isCollapsedProducts = true;
          self.gradeInfo.teacher.collapsedProductSizeHeight = 0;
        }

        // Passo 1 Percorrer
        self.gradeInfo.students.forEach((student) => {
          //  1 - Atribuição de produtos pré-render

          // 2 - Resolve os colapsados de produtos que sejam maiores do que 1
          if (student.products.length > 1) {
            student.isCollapsedProducts = true;
            student.collapsedProductSizeHeight = 0;
          }

          // 3 - (Alunos) Chamadas que resolvem o PLACEHOLDER E APÓS O RENDER TRÁS AS IMAGENS
          student.products.forEach((product, indexProductRender) => {
            product.isLoading = true;
            product.imageReady = null;

            if (indexProductRender < 2) {
              if (product.builder_uuid) {
                self.getAjaxImage(student, product, indexProductRender);
              } else {
                self.getImageOfInterval(student, product, indexProductRender);
              }
            }
          });
        });

        // 4 - (Professor) Chamadas que resolvem o PLACEHOLDER E APÓS O RENDER TRÁS AS IMAGENS
        self.gradeInfo.teacher.products.forEach(
          (productTeacher, indexProductTeacherRender) => {
            productTeacher.isLoading = true;
            productTeacher.imageReady = null;

            if (indexProductTeacherRender < 2) {
              if (productTeacher.builder_uuid) {
                self.getAjaxImage(
                  self.gradeInfo.teacher,
                  productTeacher,
                  indexProductTeacherRender
                );
              } else {
                self.getImageOfInterval(
                  self.gradeInfo.teacher,
                  productTeacher,
                  indexProductTeacherRender
                );
              }
            }
          }
        );
      })
      .catch((e) => {
        this.errors.push(e);
      });

    // Download após
    axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "/grades/download_credentials/" +
          this.teacherCredential.currentGrade.id,
        config
      )
      .then((response) => {
        this.urlPrint = response.data.data.url;
      })
      .catch((e) => {
        this.errors.push(e);
      });

    axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "/grades/extra_files/" +
          this.teacherCredential.currentGrade.id,
        config
      )
      .then((response) => {
        this.extraFiles = response.data.data.extra_files;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },

  methods: {
    getAjaxImage(student, product, indexProductRender) {
      const self = this;

      var url =
        "https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        product.builder_uuid +
        "?page=1&w=533&v=0";

      axios
        .get(url, {
          responseType: "arraybuffer", //2md 2 -- 1mb 1
        })
        .then((response) => {
          const cover = new Buffer.from(response.data, "binary").toString(
            "base64"
          );

          product.imageReady = "data:image/png;base64," + cover;
          product.isLoading = false;

          product.isLoaded = true;

          Vue.set(student.products, indexProductRender, product);
        });
    },

    getImageOfInterval(student, product, indexProductRender) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.teacherCredential.token,
        },
        signal: self.signal,
      };

      var refreshIntervalId = setInterval(function () {
        axios
          .get(
            process.env.VUE_APP_ROOT_API + "/custom_characters/" + product.id,
            config
          )

          .then((response) => {
            console.log(
              "Builder uuid que veio da resposta do customer: " +
                response.data.data.product.builder_uuid
            );

            if (response.data.data.product.builder_uuid) {
              console.log(
                "Imagem carregada = Builder uuid que veio da resposta do customer - Existente dentro da resposta:" +
                  response.data.data.product.builder_uuid
              );

              product.builder_uuid = response.data.data.product.builder_uuid;

              product.haveCache = true;
              product.randomClear = "&randomCleanCache=" + Math.random();

              product.product.builder_uuid =
                response.data.data.product.builder_uuid;

              self.getAjaxImage(student, product, indexProductRender);
              self.clearSingleInterval(refreshIntervalId);

              console.log("Loaded image");
            } else {
              console.log("Loading student images");
            }
          })
          .catch((e) => {
            //alert("Deu ruim");
            c; //onsole.log(product);
            self.clearSingleInterval(refreshIntervalId);
            console.log("Erro ao resgatar novo uuid de portabilidade.");
          });
      }, 8000);

      self.intervalList.push(refreshIntervalId);
    },

    scrollTo(offsetTopOfScrollStudent) {
      //document.getElementById()
      window.scrollTo({
        top: offsetTopOfScrollStudent,
        behavior: "smooth", // for smooth scrolling, set to 'auto' for instant scrolling
      });
    },

    openCollapseProducts(student) {
      const self = this;

      // 1 - Pegar o objeto na studentref e pegar o tamanho em algura da heightCollapsed
      const studentRef = this.$refs["student-" + student.id];

      var heightCollapsed = 0;

      if (Array.isArray(studentRef)) {
        if (studentRef.length > 0 && studentRef[0].offsetHeight) {
          heightCollapsed = studentRef[0].offsetHeight;
        }
      } else {
        if (studentRef && studentRef.offsetHeight) {
          heightCollapsed = studentRef.offsetHeight;
        }
      }

      // 2 -  Colapsed items
      if (student.collapsedProductSizeHeight == 0) {
        student.collapsedProductSizeHeight = heightCollapsed + 107;
      } else {
        var offsetTopOfScrollStudent = document
          .getElementById("student-" + student.id)
          .getBoundingClientRect().y;

        var offsetFinal = window.pageYOffset + offsetTopOfScrollStudent - 75;

        self.scrollTo(offsetFinal);
        student.collapsedProductSizeHeight = 0;
      }

      // 2 -- Buscar e trazer na lista a index de cada studen para ser atualizada
      var index = self.gradeInfo.students.findIndex(
        (obj) => obj.id === student.id
      );

      // 3 - Forçar a atualização do nó.
      Vue.set(self.gradeInfo.students, index, student);

      student.products.forEach((product, indexProductRender) => {
        if (indexProductRender > 1 && product.isLoaded != true) {
          product.isLoading = true;
          product.imageReady = null;

          if (product.builder_uuid) {
            self.getAjaxImage(student, product, indexProductRender);
          } else {
            self.getImageOfInterval(student, product, indexProductRender);
          }
        }
      });
    },

    clearSingleInterval(interval) {
      const self = this;

      clearInterval(interval);

      self.intervalList.forEach((intervalItem, index) => {
        //clearInterval(element);
        if (interval == intervalItem) {
          self.intervalList.splice(index, 1);
        }
      });

      console.log("Limpou o intervalo isolado: " + interval);
    },

    clearAllIntervals() {
      const self = this;

      self.intervalList.forEach((interval) => {
        clearInterval(interval);
      });
    },

    formatarDeDiferencaDeDias(dataFinal) {
      // var data = $('#datepicker').datepicker('getDate');
      var extenso;

      //const str = '22/04/2022';

      // Data inicial
      // var [day, month, year] = dataToFormat.split("/");
      // var data = new Date(+year, month - 1, +day);

      var data = new Date();

      // Data final
      var [day2, month2, year2] = dataFinal.split("/");
      var data2 = new Date(+year2, month2 - 1, +day2);

      //  var date1 = new Date(day +"=" + month + "/" + year);
      // var date2 = new Date("07/30/2019");

      // To calculate the time difference of two dates
      var Difference_In_Time = data2.getTime() - data.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return parseInt(Difference_In_Days);
      //$('#lblDataExtenso').html(`${day}, ${date} de ${month} de ${year}`);
    },

    formatarDataParaExtensoComNumero(dataToFormat) {
      // var data = $('#datepicker').datepicker('getDate');
      var extenso;

      //const str = '22/04/2022';

      var [day, month, year] = dataToFormat.split("/");
      var data = new Date(+year, month - 1, +day);

      //var data = new Date(dataToFormat.replace("/", "-"));

      // var day = [
      // 	"Domingo",
      // 	"Segunda-feira",
      // 	"Terça-feira",
      // 	"Quarta-feira",
      // 	"Quinta-feira",
      // 	"Sexta-feira",
      // 	"Sábado",
      // ][data.getDay()];
      var date = data.getDate();
      var month = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ][data.getMonth()];
      var year = data.getFullYear();

      //console.log(data);

      return `${date} de ${month}`;

      //$('#lblDataExtenso').html(`${day}, ${date} de ${month} de ${year}`);
    },

    onEnter(e) {
      // console.log(e);
      // e.stopPropagation();
      // e.preventDefault();
      // e.returnValue = false;

      //this.input = e.target.value

      const self = this;
      self.dedication = e.target.value;
      self.dedicationCounter = 350 - self.dedication.length;
    },

    sendDedication() {
      const self = this;

      // alert("sendDedication");
      self.$validator.validateAll().then((result) => {
        if (result) {
          let config = {
            headers: {
              Authorization: "Bearer " + self.teacherCredential.token,
            },
          };

          axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/grades/dedication/" +
                self.gradeInfo.id,
              { dedication: self.dedication },
              config
            )
            .then(function (response) {
              self.dedicationIsActive = false;
              self.oldDedication = self.dedication;
            })
            .catch(function (error) {
              let fieldError = {
                field: "error",
                msg: error.response.data.message,
                rule: "error", // optional
              };
              self.$validator.errors.add(fieldError);
            });
        }
      });
    },

    editDedication() {
      //alert("Apertou");
      const self = this;
      self.dedicationIsActive = true;

      //this.dedicationIsActive = true;
    },

    cancelEditDedication() {
      //alert("Cancel");
      this.dedication = this.oldDedication;

      this.dedicationIsActive = false;
    },

    finish() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + self.teacherCredential.token,
        },
      };

      axios
        .get(
          process.env.VUE_APP_ROOT_API + "/grades/print/" + self.gradeInfo.id,
          config
        )
        .then((response) => {
          self.gradeInfo = response.data.data.grade;
          self.closeModal();
        })
        .catch((e) => {
          self.closeModal();
          this.errors.push(e);
        });
    },

    showModal() {
      this.$refs.largeModal.open();
    },

    doLoginStudent(username, school_id, isUpdate, isTeacher) {
      const self = this;

      this.$validator.validateAll().then((result) => {
        let config = {
          headers: {
            Authorization: "Bearer " + this.teacherCredential.token,
          },
        };

        //console.log(self.login);
        let login = {
          username: username,
          school_id: school_id,
          gradeId: self.gradeInfo.id,
        };

        axios
          .post(
            process.env.VUE_APP_ROOT_API + "/students/student",
            login,
            config
          )
          .then(function (response) {
            let studentCredential = {
              token: response.data.data.token,
              user: response.data.data.user,
              main_product: response.data.data.main_product,
              multiple_products: response.data.data.multiple_products,
              uuid: response.data.data.uuid,
              dedication: response.data.data.dedication,
              isTeacher: isTeacher,
              gradeId: self.gradeInfo.id,
              haveDedication: self.teacherCredential.settings.change_dedication,
              settings: response.data.data.settings,
              isUpdate: isUpdate,
              grade: response.data.data.grade,
            };

            //console.log();

            if (response.data.data.user.name) {
              studentCredential.user.name = response.data.data.user.name;
            }

            if (response.data.data.custom_name) {
              studentCredential.user.custom_name =
                response.data.data.custom_name;
            } else {
              studentCredential.user.custom_name =
                response.data.data.user.name.split(" ")[0];
            }

            studentCredential.main_product.isEditing = true;

            studentCredential.main_product.releasePrintEnabled =
              self.gradeInfo.release_print_enabled;

            self.$store.commit("SAVE_STUDENT_CREDENTIALS", studentCredential);

            self.$router.push({
              name: "student-step-1",
              params: { isTeacher: true, grade: self.gradeInfo },
            });
          })
          .catch(function (error) {
            // console.log(error)
            this.errors.push(error);
          });
      });
    },

    doLoginStudentProduct(username, school_id, product) {
      const self = this;

      this.$validator.validateAll().then((result) => {
        let config = {
          headers: {
            Authorization: "Bearer " + this.teacherCredential.token,
          },
        };

        //console.log(self.login);
        let login = {
          username: username,
          school_id: school_id,
          gradeId: self.gradeInfo.id,
        };

        axios
          .post(
            process.env.VUE_APP_ROOT_API + "/students/student",
            login,
            config
          )
          .then(function (response) {
            let studentCredential = {
              token: response.data.data.token,
              user: response.data.data.user,
              main_product: response.data.data.main_product,
              multiple_products: response.data.data.multiple_products,
              uuid: product.builder_uuid,

              dedication: response.data.data.dedication,
              isTeacher: true,
              isUpdate: true,
              gradeId: self.gradeInfo.id,
              settings: response.data.data.settings,
              grade: response.data.data.grade,
            };

            // Condição usada para caso de uso múltiplo.
            if (product.product) {
              studentCredential.main_product = product.product;
            }

            // Condição usada para salvar a edição antiga para o backend fazer o update da nova personalização editada
            if (product.builder_uuid) {
              studentCredential.old_uuid = product.builder_uuid;
            }

            // Tratamento de nomes em partes (Verificando, nome da criança no livro, depois nome do custom_name depois da portabilidade e cortar o nome caso seja outro caso)
            if (response.data.data.user.name) {
              studentCredential.user.name = response.data.data.user.name;
            }

            if (product.name) {
              studentCredential.user.custom_name = product.name;
            } else if (response.data.data.user.custom_name) {
              studentCredential.user.custom_name =
                response.data.data.user.custom_name;
            } else if (response.data.data.user.name) {
              studentCredential.user.custom_name =
                response.data.data.user.name.split(" ")[0];
            }

            studentCredential.main_product.isEditing = true;

            // Envia as camadas e faz a rota.
            self.$store.commit("SAVE_STUDENT_CREDENTIALS", studentCredential);

            self.$router.push({
              name: "student-step-1",
              params: { isTeacher: true, grade: self.gradeInfo },
            });
          })
          .catch(function (error) {
            // console.log(error)
            this.errors.push(error);
          });
      });
    },

    async getCustomCharacter(params) {
      const self = this;

      var data = await axios
        .get(
          "https://builder.dentrodahistoria.com.br/api/customs_characters/" +
            self.studentCredential.uuid
        )
        .then(function (response) {
          if (response.data.custom.user_data.properties["mes"]) {
            self.months.forEach((element) => {
              if (
                element.name ==
                response.data.custom.user_data.properties["mes"].value
              ) {
                self.$store.commit(
                  "UPDATE_MAIN_PRODUCT_CUSTOM_MES",
                  element.key
                );
              }
            });
          }

          if (response.data.custom.user_data.properties["numero"]) {
            self.$store.commit(
              "UPDATE_MAIN_PRODUCT_CUSTOM_NUMERO",
              parseInt(
                response.data.custom.user_data.properties["numero"].value
              )
            );
          }

          self.$router.push({
            name: "preview",
            params: {
              product: params,
              typeRead: true,
              haveDedication: self.teacherCredential.settings.change_dedication,
              isPriting: self.gradeInfo.status !== "em execução",
            },
          });
        })
        .catch(function (error) {
          console.log("Erro ao resgatar UUID");
        });

      return data;
    },

    async doLoginStudentPreview(product, username, school_id) {
      const self = this;

      this.$validator.validateAll().then((result) => {
        let config = {
          headers: {
            Authorization: "Bearer " + this.teacherCredential.token,
          },
        };

        //console.log(self.login);
        let login = {
          username: username,
          school_id: school_id,
          gradeId: self.gradeInfo.id,
        };

        axios
          .post(
            process.env.VUE_APP_ROOT_API + "/students/student",
            login,
            config
          )
          .then(function (response) {
            let params = {
              builderCharacterId: product.builder_uuid,
              builderProductId: product.product_id,
              isStudent: false,
              grade: self.gradeInfo,
              isComplete: self.gradeInfo.status !== "em execução",
            };

            let studentCredential = {
              token: response.data.data.token,
              user: response.data.data.user,
              main_product: response.data.data.main_product,
              multiple_products: response.data.data.multiple_products,
              uuid: product.builder_uuid,
              dedication: response.data.data.dedication,
              isTeacher: true,
              isUpdate: true,
              gradeId: self.gradeInfo.id,
              settings: response.data.data.settings,
              grade: response.data.data.grade,
            };

            if (product.product) {
              studentCredential.main_product = product.product;
            }

            if (product.builder_uuid) {
              studentCredential.old_uuid = product.builder_uuid;
            }

            if (response.data.data.name) {
              studentCredential.user.name = response.data.data.name;
            }

            if (product.name) {
              studentCredential.user.custom_name = product.name;
            }

            if (studentCredential.user.custom_name != product.name) {
              studentCredential.user.custom_name = product.name;
            }

            if (self.gradeInfo.status != "em execução") {
              studentCredential.main_product.have_tools = false;
            }

            studentCredential.main_product.isEditing = false;

            // Tratamento de nomes em partes (Verificando, nome da criança no livro, depois nome do custom_name depois da portabilidade e cortar o nome caso seja outro caso)
            if (product.name) {
              studentCredential.user.custom_name = product.name;
            } else if (response.data.data.user.custom_name) {
              studentCredential.user.custom_name =
                response.data.data.user.custom_name;
            } else if (response.data.data.user.name) {
              studentCredential.user.custom_name =
                response.data.data.user.name.split(" ")[0];
            }

            self.$store.commit("SAVE_STUDENT_CREDENTIALS", studentCredential);

            self.getCustomCharacter(params);
          })
          .catch(function (error) {
            // console.log(error)
            this.errors.push(error);
          });
      });
    },

    showModalExample() {
      this.$refs.exampleDedication.open();
    },

    closeModal() {
      this.$refs.largeModal.close();
      //this.$refs.gradeModal.close()
      //this.$refs.exampleDedication.close()
    },

    closeExample() {
      this.$refs.exampleDedication.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-send:disabled,
.btn-send[disabled] {
  background-color: rgb(176, 161, 187);
  background-color: rgb(176, 161, 187);
  opacity: 0.6;
  color: #cdc4d4;
  width: 100%;
  &:hover {
    color: #cdc4d4;
  }
}

.schedules-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    display: block;
  }

  div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    position: relative;
    margin: 3px;
    background-color: #4965bc;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;

    span {
      font-size: 12px;
      font-weight: normal;
    }

    &:before {
      content: "";
      position: absolute;
      width: 30px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      height: 2px;
      right: -26px;
      background-color: #4965bc;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  .schedule-item {
    background-color: #fff;
    /* color/neutral/medium */

    border: 1px solid #dfdae4;
    border-radius: 8px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* color/highlight/quarternary */

    color: #4965bc;
    display: flex;
    justify-content: center;

    &:before {
      display: none;
    }

    svg {
      align-self: center;
      margin-bottom: 8px;
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* or 125% */

      text-align: center;

      /* color / neutral / darkest */

      color: #68596e;
      padding: 0px;
    }
  }
}

.footer-panel {
  margin-bottom: 0 !important;
  margin-top: 16px !important;

  .date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    padding-bottom: 0px !important;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      margin-top: -5px;
    }

    span {
      font-weight: bold;
    }

    &.date-green {
      span {
        color: #00cf72;
      }
    }

    &.date-yellow {
      span {
        color: #feaf00;
      }
    }

    &.date-red {
      span {
        color: #ff4641;
      }
    }
  }

  .action {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

.custom-teacher-table {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #afa2ba;
  margin-top: 24px;
  border-radius: 0px 0px 10px 10px;

  b {
    font-size: 14px !important;
  }

  .body-student {
    margin-top: 24px;
    border-bottom: 1px solid #dfdae4;
    // border-bottom: 1px solid #dfdae4;
    padding: 0px !important;
    margin-bottom: 0px;
    // box-shadow: 0px 10px 26px #e4d9ee;
    border-radius: 8px;
    border: 1px solid #dfdae4;
    border-top: 0px;
    border-bottom: 1px solid #dfdae4;
    width: 100%;

    &.print-area {
      border: none;
    }

    &:first-child {
      margin-top: 0px;
    }

    .empty-box-body {
      padding: 24px;
      background-color: #f9f8fc;
      display: block;

      .personalization-view {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #dfdae4;
        padding: 16px;
        box-shadow: 0px 2px 8px #4965bc3d;
      }

      .books-area {
        padding: 0px;
      }
    }
    .table-list-simple {
      margin-bottom: 0px;
    }
    .header-table {
      background-color: #4965bc;
      border-top: solid 1px #dfdae4;
      border-bottom: solid 1px #dfdae4;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #fff;
      padding: 8px 16px !important;
      border-radius: 8px 8px 0px 0px;
    }
  }

  .table-list-simple {
    margin-bottom: 0px;
  }

  .body-status {
    background-color: #fff;
    margin-top: 0px !important;
  }
}

.dedication {
  h4 {
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68596e;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #68596e;
  }

  .dedication-area {
    position: relative;
    border-radius: 8px;
    border: solid 2px #dfdae4;
    background-color: #f8f7fc;
    height: 231px;
    width: 100%;
    padding: 34px 17px;
    color: #afa2ba;
    padding-bottom: 10px;
    text-align: left;

    &.active {
      border-radius: 6px;
      border: solid 2px #00e0a1;
      color: #000;
    }

    &.red {
      border-color: red;
    }

    span {
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #afa2ba;
      position: absolute;
      top: 15px;
      left: 20px;
    }

    textarea {
      position: relative;
      top: 10px;
      width: 100%;
      background-color: transparent;
      height: 100%;
      border: 0px;
      font-size: 16px;
    }

    .counter {
      position: absolute;
      top: -10px;
      right: 23px;
      width: 93px;
      height: 21px;
      border-radius: 100px;
      background-color: #dfdae4;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding-top: 4px;

      &.active {
        background-color: #00e0a1;
      }

      &.red {
        background-color: red;
      }
    }
  }

  .button-area {
    // padding-bottom: 0px !important;
    padding: 16px !important;
  }

  .btn-white {
    background-color: #fff;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: 0.57px;
    text-align: center;
    color: #4965bc;
    border: 0px;
    padding: 0px;
  }
}
::v-deep .vuestic-modal .modal-header {
  display: none;
}
.phrase-secure {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #68596e;
  margin-top: 0px !important;
  // padding-left: 8px;
  // padding-right: 8px;
  padding: 16px 16px;
  // margin-bottom:20px !important;

  b {
    color: #68596e !important;
  }

  .status-text {
    display: flex;
    align-items: center;
  }

  .btn {
    height: 65px;
    width: 100%;
    min-width: 260px;
    max-width: 357px;
    font-size: 14px;
    border-radius: 100px;
  }
}

.teacher-flag {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #9378f0;
  line-height: 30px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: white;
  }
}
.cover-colum {
  min-width: 128px;
  padding: 0px !important;
}
.bordered-cover {
  position: relative;
  max-width: 128px;
  max-height: 182px;
  img {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0 #68596e24;
  }

  .book-covers {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    border-radius: 4px;
  }
}

.empty-item {
  cursor: pointer;

  &:hover {
    .empty-book {
      border-color: #4965bc;
      // 	border: 1px solid #4965bc;
      // 	color: #4965bc;
    }
  }
}
.empty-book {
  width: 100%;
  height: 100%;
  height: 187px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  font-size: 25px;

  border-radius: 8px;

  border-radius: 8px;
  height: 100%;
}
.btn-micro-edit {
  font-family: "Nunito";
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.67px;
  text-align: center;
  color: #4965bc;
  background: none;
  border: none;
  width: 100%;
}
.student-name {
  font-stretch: normal;
  letter-spacing: normal;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  display: flex;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 31px;
  color: #68596e;
  padding: 0px !important;
  width: calc(100% - 320px);

  .book-name {
    padding-left: 16px;
  }

  div {
    flex: unset;
  }

  // span {
  // 	width: 100%;
  // 	font-weight: bold;
  // 	font-size: 12px;
  // 	margin-top: 10px;
  // 	display: block;
  // }
}
.student-code {
  font-stretch: normal;
  letter-spacing: 1.11px;
  justify-content: center;
  flex-direction: column;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;

  .student-username {
    color: #fff;
    font-weight: bold;
  }
}

.teacher-grade {
  background-color: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68596e;

  .btn {
    width: 275px;
    height: 45px;
    border-radius: 100px;
    // background-color: #9378f0;
    background-image: none;
    position: relative;
    line-height: 20px;
    top: 3px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;
      top: 15px;
    }

    &:hover {
      // background-color: #9378f0;
    }
    span {
      font-size: 17px;
      position: absolute;
      top: 15px;
      right: 23px;
    }
    svg {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

  .teacher-name {
    line-height: 45px;
  }
}

.body-teacher {
  margin-top: 20px;

  .body-spacing .panel {
    // padding: 10px 25px;

    h4 {
      margin-top: 0px;
      margin-bottom: 8px;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66596e;
    }
  }

  .table-list-simple {
    box-shadow: 0px 10px 26px #e4d9ee;
  }
}

.my-account {
  position: relative;
  //top: 0px;
  background-color: #f8f7fc;
  z-index: 3;
  width: 100%;
  padding-bottom: 30px;
  //min-height: 100%;
  //overflow: hidden;
  // height: 100%;
}

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: #00cf72;
  margin-bottom: 20px;
  display: block;

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }

  .logo-client {
    position: absolute;
    bottom: 0px;
    display: block;
    right: 0px;
    margin: 0px;
    float: right;

    @media (max-width: 990px) {
      display: none;
    }
  }

  .school-name-short {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 22px;
    text-transform: capitalize;
    color: #68596e;
    display: flex;
    align-items: center;
  }

  .school-name {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
    display: flex;

    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;

    h4 {
      font-family: "Nunito";
      font-size: 40px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 0;
      display: flex;
      align-items: center;
      // line-height: 70px;
    }

    span {
      font-size: 16px;
      font-weight: 200 !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .back-circle {
      width: 60px;
      height: 60px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      // display: inline-block;
      text-align: center;
      border-radius: 30px;
      position: relative;
      float: left;
      margin-right: 10px;
      top: 0px;
      margin-top: 0px;
      margin-bottom: 0px;

      svg {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
  }

  .header-teacher {
    position: relative;

    .ddh-item {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }

    .ddh-prof {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;

      a {
        border: 0;
        background: none;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
      }
    }
  }
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}

h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}

.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel-grade {
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: transparent;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
  .info-grade {
    padding-bottom: 24px;
    font-family: "Roboto";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68596e;
    padding-top: 0px;

    .info-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */

      color: #68596e;
    }

    .btn {
      color: #4965bc;
      background-color: #fff;
      border: 1px solid #4965bc;
    }
  }
  .sent {
    margin-top: 24px;
  }
  .print-sent {
    align-items: center;
    display: flex;
  }
  .list-add-students {
    // padding-top: 24px;
    .btn-add {
      width: 100%;
      padding: 25px;
      border-radius: 100px;
      margin-bottom: 24px;
    }
    .btn-success-print-button {
      background: #00cf72;
    }
  }

  .panel-gray {
    // border-radius: 10px;
    // // border: solid 1px #dfdae4;
    // background-color: transparent;
    // overflow: hidden;

    .btn {
      border-radius: 100px;
      height: 44px;
      border-radius: 100px;
      background-color: #4965bc;
      // padding-left: 70px;
      position: relative;
      font-size: 12px;
      line-height: 12px;
      width: 100%;
      margin: 6px 0px;
      min-width: 160px;

      &:disabled {
        background-color: #cdc4d4;
        border-color: #cdc4d4;
        width: 100%;
      }

      @media (max-width: 990px) {
        padding: 0px;
        width: 100%;
      }
      span {
        width: 45px;
        height: 45px;
        border-radius: 100px;
        background-color: #ffffff;
        font-size: 41px;
        display: inline-block;
        color: #4965bc;
        line-height: 43px;
        position: absolute;
        left: 8px;
        top: 8px;

        @media (max-width: 990px) {
          display: none;
        }
      }
    }
    .inverter {
      background-color: #fff;
      color: #4965bc;
      border: 1px solid #4965bc;
    }
  }
}

.panel {
  border-radius: 10px;
  background-color: #ffffff;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 10px 25px 0 #e2ddf6;
  background-color: #ffffff;

  &.right {
    padding: 24px !important;
    padding-bottom: 0px !important;
  }

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.btn-add {
  @media (max-width: 767px) {
    line-height: 61px !important;
  }
}

.table-list-simple {
  margin: 0 auto;
  margin-bottom: 32px;

  .header {
    background: #00cf72;
    color: white;
  }
}

.student-list {
  border-radius: 16px;
  margin-bottom: 0px;
  border: 0px;
  border-bottom: 1px solid #dfdae4;
  box-shadow: 0px 10px 26px #e4d9ee;

  &.list-empty {
    border-bottom: 0px;
    border-radius: 16px;
  }
  hr {
    margin: 0px;
  }
}

.empty-students {
  border: 1px dashed #4965bc;
  border-radius: 10px;
  padding: 72px 110px;
  width: 100%;
  margin-top: 24px;

  h3 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */

    text-align: center;

    color: #68596e;
    margin-bottom: 32px;
  }

  .btn {
    border-radius: 20px;
    border: none;
    background-color: #4965bc;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    font-family: "Nunito";
    padding: 26px 136px;
    border-radius: 100px;
  }
}

.btn-ghost {
  &:disabled {
    color: #4965bc;
    background-color: #fff;
    border-color: #4965bc;
    color: #dfdae4;
    padding: 16px 24px;
    border: 1px solid #dfdae4;
  }
}

.body-spacing {
  max-width: 1160px;
  margin: 0 auto;
}

.empty-box-body {
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  transition: ease-in-out 0.6s; /* Define a transição para max-height */

  .schedules-list {
    margin: 0px;
    padding: 0px;
    margin-bottom: 24px;

    .schedule-item {
      background-color: #fff;
      /* color/neutral/medium */

      border: 1px solid #dfdae4;
      border-radius: 8px;

      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      /* color/highlight/quarternary */

      color: #4965bc;
      display: flex;
      justify-content: center;

      &:before {
        display: none;
      }

      svg {
        align-self: center;
        margin-bottom: 8px;
      }

      span {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* or 125% */

        text-align: center;

        /* color / neutral / darkest */

        color: #68596e;
        padding: 0px;
      }
    }
  }
}

.student-row {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfdae4;
  padding: 20px;
  box-shadow: 0px 2px 8px #4965bc3d;
  margin-bottom: 16px !important;

  &:last-child {
    margin-bottom: 0px !important;
  }
}

.cover-loading {
  position: relative;
}

.loading-spinner-book {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;

  svg {
    width: 128px;
    height: 182px;
    transform: scale(0.6);
  }
}

.nome-da-marca {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #afa2ba;
}
.label-position-codigo {
  position: relative;
  right: 12px;
}
.align-name-student {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.collapsed-closed {
  min-height: 412px;
}

.collapsed-open {
  //min-height: 412px; /* Altura máxima quando aberto, um valor grande para garantir que o conteúdo seja totalmente exibido */
  //padding-bottom: 76px !important; /* Adicione o padding necessário */
}

.btn-collapse-control {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: #f4f2fb;
  color: #4965bc;
  font-size: 14px;
  font-weight: 900;
  border: none;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #dfdae4;
  box-shadow: 0px -4px 8px -2px #afa2ba64;
  padding: 16px 0px;

  span {
    font-family: "Nunito";
    text-transform: uppercase;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }
}

.por-forinha {
  display: block;
}

.table-list-simple .header .item {
  font-size: 14px !important;
  width: 100%;
}

#loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
</style>
